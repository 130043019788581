.about__intro {
  width: 100%;
  box-sizing: border-box;
  padding: var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--orange);

  @media (--laptop) {
    padding: 16rem calc(var(--columns-2) + var(--margin) + var(--gutter)) 16rem
      calc(var(--columns-5) + var(--margin) + var(--gutter));
  }

  p + p {
    margin-top: 3.2rem;
  }
}
