.events {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;

  &__year {
    width: 100%;

    &__title {
      width: 100%;
      height: 100vh;
      background-color: var(--green);
      color: currentColor;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .p6 {
        position: absolute;
        left: -20rem;
      }
    }

    &__body {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &__calendar {
        display: none;

        @media (--laptop) {
          width: var(--columns-3);
          height: calc(100vh - var(--header-height));
          margin: 0 calc(var(--column) + (2 * var(--gutter))) 0 var(--margin);
          display: block;
          flex-shrink: 0;
        }

        &::before {
          content: "";
          width: 1px;
          height: calc(100% - (2 * var(--margin)));
          background-color: currentColor;
          position: absolute;
          top: 50%;
          left: calc(var(--column) / 2);
          transform: translateY(-50%);
        }

        &__text {
          position: absolute;
          left: calc(var(--column) / 6);

          &--start {
            top: var(--margin);
          }

          &--middle {
            padding: 12rem 0;

            p {
              &::before {
                content: attr(data-calendar-month);
              }
            }
          }

          &--end {
            bottom: var(--margin);
          }
        }
      }

      &__months {
        width: 100%;
        box-sizing: border-box;
        margin: 0 var(--margin);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (--laptop) {
          margin-right: var(--margin);
        }

        &__month {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &__title {
            @media (--laptop) {
              display: none;
            }
          }

          & + & {
            .events__year__body__months__month__events__event {
              &::before {
                @media (--laptop) {
                  content: "";
                  width: 100%;
                  height: 1px;
                  background-color: currentColor;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
          }

          &__events {
            &__event {
              width: 100%;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 4rem 0;
              position: relative;
              padding-right: calc(2 * var(--full-column));

              &__title {
                margin-top: 1.6rem;
              }

              & + & {
                &::before {
                  content: "";
                  width: 100%;
                  height: 1px;
                  background-color: currentColor;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
