.page-transitioner {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  z-index: var(--page-transitioner-z-index);
  box-sizing: border-box;
  pointer-events: none;
  background-color: var(--white);
  opacity: 0;
  transition: opacity 0.6s linear;

  &--visible {
    opacity: 1;
  }
}
