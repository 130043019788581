.splash {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;
  background-image: url(https://user-images.githubusercontent.com/41680436/231393353-c13a12e9-96f0-4af2-9d51-6a4d6b89d689.png);
  background-position: bottom left;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fa3f0b;

  @media (--laptop) {
    background-image: url(https://user-images.githubusercontent.com/41680436/231393362-b1750cd4-1615-4844-9aee-d14a898de346.png);
  }
}
