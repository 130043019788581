.footer {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: var(--margin) var(--margin) 12rem;
  margin-top: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--white);
  color: var(--orange);

  @media (--laptop) {
    flex-direction: row;
    padding: 1.6rem var(--margin) 14.4rem;
    margin-top: 16rem;
  }

  .page-events & {
    background-color: var(--green);
    color: currentColor;
  }

  &::before {
    content: "";
    width: calc(100% - (var(--margin) * 2));
    height: 1px;
    position: absolute;
    top: 0;
    left: var(--margin);
    background-color: currentColor;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (--laptop) {
      width: var(--columns-6);
    }

    & + & {
      margin-top: 3.2rem;

      @media (--laptop) {
        margin-top: 0;
        margin-left: var(--gutter);
      }
    }

    &:last-child {
      @media (--laptop) {
        width: auto;
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}
