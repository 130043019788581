.header {
  width: 100%;
  padding: 1.6rem var(--margin);
  box-sizing: border-box;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--header-z-index);

  .about & {
    background-color: var(--white);
    color: var(--orange);
  }

  .page-events & {
    background-color: var(--green);
    color: currentColor;
  }

  &::before {
    content: "";
    width: calc(100% - (2 * var(--margin)));
    height: 1px;
    position: absolute;
    top: 0;
    left: var(--margin);
    background-color: currentColor;
  }

  &__menu,
  &__menu.animated-text {
    display: none;

    @media (--laptop) {
      display: flex;
      align-items: flex-end;
    }

    &__item {
      & + & {
        @media (--laptop) {
          margin-left: 1.6rem;
        }
      }
    }
  }

  &__nav-toggle.animated-text {
    @media (--laptop) {
      display: none;
    }

    &.animated-text--in-view {
      .animated-text__row {
        .nav-visible & {
          transform: translate3d(0, -110%, 0);
        }
      }
    }
  }
}
