.contact {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: var(--margin);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--white);
  color: var(--orange);

  .page-events & {
    background-color: var(--green);
    color: currentColor;
  }

  @media (--laptop) {
    flex-direction: row;
    padding: 1.6rem var(--margin);
  }

  &::before {
    content: "";
    width: calc(100% - (2 * var(--margin)));
    height: 1px;
    background-color: currentColor;
    position: absolute;
    top: 0;
    left: var(--margin);
  }

  > p {
    @media (--laptop) {
      width: var(--columns-6);
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (--laptop) {
      width: var(--columns-6);
      margin-left: var(--gutter);
    }

    li {
      &:first-child {
        @media (--laptop) {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
}
