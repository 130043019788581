.paperbar {
  width: 100%;
  overflow: hidden;
  position: relative;
  color: var(--orange);

  @media (--laptop) {
    padding: 0;
  }

  &::before {
    content: "";
    width: calc(100% - (2 * var(--margin)));
    height: 1px;
    background-color: currentColor;
    position: absolute;
    top: 0;
    left: var(--margin);
  }

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__items {
    width: 100vw;

    @media (--laptop) {
      height: calc(100vh - var(--header-height));
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    &__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: var(--margin);
      box-sizing: border-box;
      will-change: transform;

      @media (--laptop) {
        width: fit-content;
        max-height: calc(var(--columns-8) + var(--column) + var(--gutter) * 2);
        box-sizing: content-box;
        padding: 8rem calc(var(--margin) + var(--columns-3) + var(--gutter));
        flex-direction: row;
      }
    }
  }

  &__video {
    width: 100%;
    height: 0;
    padding-bottom: 64%;
    position: relative;

    @media (--laptop) {
      width: var(--columns-13);
      height: 100%;
      padding-bottom: 0;
      margin-right: calc(var(--column) + var(--gutter) * 2);
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__works {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;

    @media (--laptop) {
      width: fit-content;
      height: 100%;
      flex-flow: row;
    }

    &__work {
      width: var(--columns-2);
      height: var(--columns-2);
      flex-shrink: 0;

      @media (--laptop) {
        width: var(--columns-4);
        height: var(--columns-4);
      }

      &:nth-child(6n + 1) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: calc(var(--columns-3) + var(--gutter));

        @media (--laptop) {
          align-self: flex-start;
          margin-top: 0;
          margin-left: calc(var(--columns-2) + var(--gutter) * 2);
        }

        &:first-child {
          margin-top: 0;
          @media (--laptop) {
            margin-left: 0;
          }
        }
      }

      &:nth-child(6n + 2) {
        margin-top: calc(var(--column) + var(--gutter));

        @media (--laptop) {
          margin-top: 0;
          align-self: flex-end;
          margin-left: calc(var(--columns-2) + var(--gutter) * 2);
        }
      }

      &:nth-child(6n + 3) {
        margin-left: var(--gutter);
        margin-top: calc(var(--column) + var(--gutter));

        @media (--laptop) {
          margin-top: 0;
          align-self: flex-start;
          margin-left: calc(var(--columns-1) + var(--gutter) * 2);
        }
      }

      &:nth-child(6n + 4) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: calc(var(--columns-2) + var(--gutter));

        @media (--laptop) {
          margin-top: 0;
          align-self: flex-start;
          margin-left: calc(var(--columns-2) + var(--gutter) * 2);
        }
      }

      &:nth-child(6n + 5) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: 0;

        @media (--laptop) {
          align-self: flex-end;
          margin-left: var(--gutter);
        }
      }

      &:nth-child(6n + 6) {
        margin-top: calc(var(--column) + var(--gutter));
        margin-left: calc(var(--columns-1) + var(--gutter) * 2);

        @media (--laptop) {
          align-self: flex-end;
          margin-left: calc(var(--columns-1) + var(--gutter) * 2);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
