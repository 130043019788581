:root {
  --gutter: 0.8rem;
  --margin: 2rem;
  --column: calc((100vw - (4 * var(--gutter)) - (2 * var(--margin))) / 5);
  --full-column: calc(var(--column) + var(--gutter));

  --columns-1: calc((1 * var(--column)));
  --columns-2: calc((2 * var(--column)) + (1 * var(--gutter)));
  --columns-3: calc((3 * var(--column)) + (2 * var(--gutter)));
  --columns-4: calc((4 * var(--column)) + (3 * var(--gutter)));
  --columns-5: calc((5 * var(--column)) + (4 * var(--gutter)));
  --columns-6: calc((6 * var(--column)) + (5 * var(--gutter)));
  --columns-7: calc((7 * var(--column)) + (6 * var(--gutter)));
  --columns-8: calc((8 * var(--column)) + (7 * var(--gutter)));
  --columns-9: calc((9 * var(--column)) + (8 * var(--gutter)));
  --columns-10: calc((10 * var(--column)) + (9 * var(--gutter)));
  --columns-11: calc((11 * var(--column)) + (10 * var(--gutter)));
  --columns-12: calc((12 * var(--column)) + (11 * var(--gutter)));
  --columns-13: calc((13 * var(--column)) + (12 * var(--gutter)));

  @media (--laptop) {
    --margin: 3.2rem;
    --gutter: 0.8rem;
    --column: calc((100vw - (23 * var(--gutter)) - (2 * var(--margin))) / 24);
  }
}

.columns {
  --red: rgba(255, 0, 0, 0.24);
  margin: 0 var(--margin);
  width: calc(100vw - (2 * var(--margin)));
  height: 100vh;
  background: repeating-linear-gradient(
    to right,
    var(--red),
    var(--red) var(--column),
    transparent var(--column),
    transparent var(--full-column)
  );
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  display: block;
  z-index: var(--columns-z-index);

  &--hidden {
    opacity: 0;
  }
}
