html {
  font-size: 2.6666666667vw;

  @media (--laptop) {
    font-size: 0.7874015748vw;
  }
}

body {
  background-color: var(--background-color);
  padding-bottom: var(--header-height);
}

* {
  margin: 0;
  padding: 0;
  cursor: none !important;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
