.about {
  background-color: var(--white);
  color: var(--orange);

  &__paragraph {
    width: 100%;
    min-height: calc(100vh - var(--header-height));
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: var(--margin);

    @media (--laptop) {
      padding: var(--margin)
        calc(var(--margin) + var(--columns-3) + var(--gutter));
    }

    &--right {
      justify-content: flex-end;
    }

    &__text {
      z-index: 1;

      @media (--laptop) {
        width: var(--columns-10);
      }
    }

    @keyframes about-gif {
      0% {
        transform: translate3d(-100%, -50%, 0);
      }

      100% {
        transform: translate3d(calc(100vw + 100%), -50%, 0);
      }
    }

    &__gif {
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(-100%, -50%, 0);
      animation: about-gif 10s linear infinite;
      background-color: yellow;
      z-index: 0;

      .about__paragraph--right & {
        animation-direction: reverse;
      }
    }
  }
}
