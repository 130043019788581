:root {
  --white: #ffffff;
  --black: #000000;
  --red: #e93e15;
  --green: #a2bd3f;
  --orange: #fe4c10;
  --background-color: var(--orange);
  --text-color: var(--white);
}

.black {
  color: var(--black);
}
