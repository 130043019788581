.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--loader-z-index);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;
  user-select: none;
  opacity: 1;
  color: var(--orange);
  background-color: var(--white);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  will-change: clip-path;
  transition: clip-path var(--transition-duration--slower)
    var(--transition-ease--in-out-quint);

  .about & {
    background-color: var(--orange);
    color: var(--white);
  }

  &--hidden {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    transition-duration: var(--transition-duration--slowest);
  }

  &__header {
    width: 100%;
    padding: 1.6rem var(--margin);
    box-sizing: border-box;
    z-index: 2;
    position: relative;

    &__line {
      width: calc(100% - (2 * var(--margin)));
      height: 1px;
      position: absolute;
      top: 0;
      left: var(--margin);
      background-color: currentColor;
      transform-origin: center left;
      will-change: transform;
      transform: scaleX(0);
    }

    &__text {
      position: absolute !important;
      left: var(--margin);
      bottom: calc(100% + 0.8rem);
    }

    &__logo {
      opacity: 0;
      pointer-events: none;
    }
  }
}
