.works {
  width: 100%;
  overflow: hidden;

  @media (--laptop) {
    padding: 0;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__items {
    width: 100vw;

    @media (--laptop) {
      height: calc(100vh - var(--header-height));
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    &__wrapper {
      width: 100%;
      height: 100%;
      padding: var(--margin);
      box-sizing: border-box;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      will-change: transform;

      @media (--laptop) {
        width: fit-content;
        max-height: calc(var(--columns-8) + var(--column) + var(--gutter) * 2);
        box-sizing: content-box;
        padding: 8rem calc(var(--margin) + var(--columns-3) + var(--gutter));
        flex-flow: row;
      }

      &__item {
        width: var(--columns-2);
        height: var(--columns-2);
        flex-shrink: 0;
        position: relative;

        @media (--laptop) {
          width: var(--columns-4);
          height: var(--columns-4);
        }

        &:nth-child(6n + 1) {
          margin-top: calc(var(--column) + var(--gutter));
          margin-left: calc(var(--columns-3) + var(--gutter));

          @media (--laptop) {
            align-self: flex-start;
            margin-top: 0;
            margin-left: calc(var(--columns-2) + var(--gutter) * 2);
          }

          &:first-child {
            margin-top: 0;

            @media (--laptop) {
              margin-left: 0;
            }
          }
        }

        &:nth-child(6n + 2) {
          margin-top: calc(var(--column) + var(--gutter));

          @media (--laptop) {
            margin-top: 0;
            align-self: flex-end;
            margin-left: calc(var(--columns-2) + var(--gutter) * 2);
          }
        }

        &:nth-child(6n + 3) {
          margin-left: var(--gutter);
          margin-top: calc(var(--column) + var(--gutter));

          @media (--laptop) {
            margin-top: 0;
            align-self: flex-start;
            margin-left: calc(var(--columns-1) + var(--gutter) * 2);
          }

          .works__items__wrapper__item__title {
            right: calc(100% + var(--gutter));
            left: auto;
          }
        }

        &:nth-child(6n + 4) {
          margin-top: calc(var(--column) + var(--gutter));
          margin-left: calc(var(--columns-2) + var(--gutter));

          @media (--laptop) {
            margin-top: 0;
            align-self: flex-start;
            margin-left: calc(var(--columns-2) + var(--gutter) * 2);
          }
        }

        &:nth-child(6n + 5) {
          margin-top: calc(var(--column) + var(--gutter));
          margin-left: 0;

          @media (--laptop) {
            align-self: flex-end;
            margin-left: var(--gutter);
          }

          .works__items__wrapper__item__title {
            right: calc(100% + var(--gutter));
            left: auto;
          }
        }

        &:nth-child(6n + 6) {
          margin-top: calc(var(--column) + var(--gutter));
          margin-left: calc(var(--columns-1) + var(--gutter) * 2);

          @media (--laptop) {
            align-self: flex-end;
            margin-left: calc(var(--columns-1) + var(--gutter) * 2);
          }
        }

        &__image {
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
          will-change: filter;
          transition: filter var(--transition-duration--fast)
            var(--transition-ease--out-quint);
        }

        &__title {
          position: absolute;
          top: 0%;
          left: calc(100% + var(--gutter));
          opacity: 0;
        }

        &:hover {
          .works__items__wrapper__item__image {
            @media (--laptop) {
              filter: blur(16px);
            }
          }

          .works__items__wrapper__item__title {
            @media (--laptop) {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
