:root {
  /* "loader": 900,
    "modal": 800,
    "header": 700,
    "menu": 600 */

  --page-transitioner-z-index: 699;
  --submenu-z-index: 702;
  --cart-modal-z-index: 800;
  --cookie-banner-z-index: 800;
  --scroll-down-z-index: 992;
  --header-z-index: 993;
  --page-transitioner-z-index: 994;
  --nav-z-index: 995;
  --dots-z-index: 996;
  --cursor-z-index: 997;
  --loader-z-index: 998;
  --columns-z-index: 999;
  --hero-height: 100vh;
  --home-marquee-height: 7.2rem;
  --page-margin: 6rem;

  @media (--laptop) {
    --home-marquee-height: 8.3rem;
    --page-margin: 14rem;
  }
}
