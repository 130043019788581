.nav {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 1.6rem var(--margin);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--text-color);
  color: var(--background-color);
  position: fixed;
  top: 0;
  left: 0;
  clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  will-change: clip-path;
  transition: clip-path var(--transition-duration--more-fast)
    var(--transition-ease--in-out-quint);
  pointer-events: none;
  z-index: var(--nav-z-index);

  .nav-visible & {
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);
    pointer-events: initial;
    opacity: 1;
  }

  &__menu {
    width: 100%;
    box-sizing: border-box;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 6.4rem;
  }

  &__header {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding-top: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--background-color);
    }

    &__nav-toggle.animated-text {
      &.animated-text--in-view {
        .animated-text__row {
          transform: translate3d(0, 110%, 0);

          .nav-visible & {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }
}
