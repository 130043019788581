.scroll-down {
  width: 8rem;
  height: 8rem;
  position: fixed;
  bottom: calc(var(--header-height) + 3.2rem);
  right: var(--margin);
  z-index: var(--scroll-down-z-index);
  background-image: url("/assets/icons/arrow-down.svg");
  background-position: bottom left;
  background-size: contain;
  animation: fadein-fadeout 1s linear infinite alternate;
  opacity: 1;
  transition: opacity 1s linear;

  &--hidden {
    animation: none;
    opacity: 0;
  }
}

@keyframes fadein-fadeout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
