.cursor {
  width: 4rem;
  height: 4rem;
  box-sizing: border-box;
  z-index: var(--cursor-z-index);
  position: fixed;
  top: 0;
  left: 0rem;
  pointer-events: none;
  display: none;
  mix-blend-mode: multiply;

  @media (--laptop) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__circle {
    width: 13.5rem;
    height: 13.5rem;
    border-radius: 50%;
    background-color: var(--orange);
    position: absolute;
    top: 50%;
    left: 50%;
    filter: blur(24px);
  }
}
