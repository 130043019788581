@font-face {
  font-family: "Museo Slab 500";
  src: url("/assets/font/MuseoSlab-500.woff2") format("woff2"),
    url("/assets/font/MuseoSlab-500.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
