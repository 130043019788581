.dots {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: var(--dots-z-index);
  opacity: 1;

  &--visible {
    opacity: 1;
  }

  &__dot {
    width: 8rem;
    height: 8rem;
    position: absolute;
    background-color: var(--orange);
    border-radius: 50%;
    filter: blur(24px);
    -webkit-filter: blur(24px);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    overflow: visible;
    mix-blend-mode: multiply;
  }
}
